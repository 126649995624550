<template>
  <div btn-go-apply>
    <button :class="{landing}" @click="goApply" />
  </div>
</template>

<script>
import gtag from 'shared/mixins/gtag';

export default {
  name: 'TftSectionTitle',
  props: {
    eventName: { type: String, default: '' },
    landing: { type: Boolean, default: false },
  },
  mixins: [gtag],
  computed: {
    upRouteGameId() {
      return this.$route.params?.id?.toUpperCase();
    },
  },
  methods: {
    goApply() {
      this.trackEvent(`${this.eventName}`, 'click', `${this.upRouteGameId}`);
      if (this.landing) this.$router.push('/b/tft_weeklycup/leagueGuide');
      else this.$router.push('/b/tft_weeklycup?page=arena');
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';

@keyframes loopGoApply {
  0% { filter: contrast(100%); transform: scale(1); }
  30% { filter: contrast(200%); transform: scale(1.005); }
  80%,
  100% { filter: contrast(100%); transform: scale(1); }
}
[btn-go-apply] {
  button { .wh(355, 77); .fs(0, 0); .c(transparent); .no-repeat('@{tft-intro}/btn_go_apply_2x.png'); .bg-s(100%, 100%);
    animation: loopGoApply 2.5s ease-in-out infinite;
    &:hover { animation: none; }
    &.landing { .no-repeat('@{tft-home}/info_button_pc_2x.png');}
  }

  @media (@tl-up) {
    button { .wh(506, 110); }
  }
  @media (@ds-up) {
  }
}
</style>
