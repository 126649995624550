<template>
  <div tft-home>
    <HomeHeader />
    <OfficialLeague :officialLeagueData="officialLeagueData">
      <template #detail>
        <TftDetail />
        <TftGameInfo />
      </template>
    </OfficialLeague>
    <client-only><MyInfo /></client-only>
  </div>
</template>

<script>
import gtag from '@shared/mixins/gtag';
import OfficialLeague from '@/views/components/gameHome/OfficialLeague.vue';
import HomeHeader from '@/views/components/gameHome/HomeHeader.vue';
import MyInfo from '@/views/components/gameHome/MyInfo.vue';
import officialLeagueData from '@/data/official-league/officialLeagueData';
import TftDetail from '@/views/components/gameHome/tft/TftDetail.vue';
import TftGameInfo from '@/views/components/gameHome/tft/gameInfo/TftGameInfo.vue';
import meta from '@/data/meta';

export default {
  name: 'tftHome',
  mixins: [gtag],
  components: { TftGameInfo, TftDetail, MyInfo, HomeHeader, OfficialLeague },
  computed: {
    officialLeagueData() {
      return officialLeagueData[this.$route.params.id];
    },
  },
  methods: {
    toInit() {
      setTimeout(() => {
        const target = document.querySelector('.league');
        this.$scroll.scrollMove(target.offsetTop + (this.matchedMediaDevice === 'M' ? 450 : 700), 1000);
      }, 200);
    },
  },
  mounted() {
    if (this.$route.query.page && this.$route.query.page === 'arena') this.toInit();
  },
  metaInfo() {
    return meta[this.$route.params.id][this.$route.name];
  },

};

</script>s

<style lang="less">
@import '~@/less/proj.less';

[tft-home] {
  [official-league] { .cover('@{tft-home}/BG.jpg');
    .progress-holder {
      .inside { }
    }
    .slider-holder { .pb(90);
      &:before { .abs; .lt(50%, 121); .t-x(-50%); .cnt; .wh(100%, 326); .max-w(1794); .cover('@{tft-home}/asset_3_m.svg');}
      .card-list-holder { .h(656);
        .tab { .hide;}
        .msg-tft { .tc; .mb(32); .font-g;
          > h4 { .fs(24, 24); .ls(-1); .mb(8);}
          > em { .c(#d4ff60); .fs(18, 18); .ls(-1);}
        }
      }
    }
    .btn-ask { .bgc(#1a4fb0) !important; }
  }
  @media (@tl-up) {
    [official-league] {
      .progress-holder {
        .inside { }
      }
      .slider-holder {
        &:before { .wh(100%, 412); .t(194); .bg-c; .bg('@{tft-home}/asset_3_pc.svg'); }
        .card-list-holder { .h(764);
          .msg-tft { .mb(85);
            > h4 { .fs(42, 42);}
            > em { .fs(22, 22);}
          }
        }

      }
    }
    .btn-ask { .w(140) !important;}
  }
  @media (@ds-up) {
    [official-league] {
      .slider-holder {
        &:before { .t(177);}
        .arrow-wrap {
          .hide;
        }
      }
    }
  }

}

</style>
