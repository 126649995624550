<template>
  <PSection tft-game-info theme="white">
    <PItem class="info-section">
      <div class="contents-container">
        <div class="contents-holder">
          <div class="top">
            <p>총 상금 600만원과 함께</p>
            <h4>레전드 컵 시드권이 내 손안에!</h4>
          </div>
          <div class="bottom">
            <img class="img" src="/img/pages/tftWeeklyCup/home/legend_cup.png" />
          </div>
        </div>
        <p class="detail"><b>자세한 대회 내용</b>은 아래 버튼 클릭!</p>
        <BtnGoApply landing event-name="Main_landing_btn" />
      </div>
      <div class="divider"></div>
    </PItem>
    <div class="chars">
      <figure class="char1" v-prx:intro="{ r: [7, 9], tx: [200, 0], ty: [250, 0], o: [0, 1] }">
        <img src="/img/pages/tftWeeklyCup/home/cha_01.png" alt="">
      </figure>
      <figure class="char2" v-prx:intro="{ r: [12, 15], tx: [-100, 0], o: [0, 1] }">
        <img src="/img/pages/tftWeeklyCup/home/cha_02.png" alt="">
      </figure>
    </div>
  </PSection>
</template>

<script>
import BtnGoApply from '@/views/components/landing/tftWeeklyCup/BtnGoApply.vue';

export default {
  name: 'TftGameInfo',
  components: { BtnGoApply },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
@keyframes dodongsilTft {
  0% { transform+_: translateY(-10px) scale(1); }
  40% { transform+_: translateY(10px) scale(.98); }
  100% { transform+_: translateY(-10px) scale(1); }
}

[tft-game-info] { .tc; .font-g; .mt(59);
  .info-section { .ani-base;
    .intro-up(1, {.ani-on});
    .contents-container { .wh(100%, 434); .pt(28); .bg('@{tft-home}/sub-bg_m.svg'); .no-repeat; .bg-c; .max-w(375); .mh-c;
      .contents-holder { .tc; .pt(28); .mb(22); .bg('@{tft-home}/asset_2-2_m.svg'); .no-repeat; .bg-c; .wh(100%, 264);
        .top { .mb(24);
          > p { .block; .mb(4); .c(#d4ff60); .fs(18, 18); .ls(-1); .bold; }
          > h4 { .c(#00cbd8); .fs(24, 24); .ls(-1);}
        }
        .bottom {
          .img { .wh(360, 129);}
        }
      }
      .detail { .fs(18, 32); .ls(-1); }
    }
    .divider { .bg('@{tft-home}/div_m.svg'); .bg-c; .no-repeat; .wh(272, 8); .mh-c; .mt(58); .mb(10);

    }
  }
  .chars { .hide; .abs; .lt(0, 0); .w(100%); .z(2);
    .char1 img { .abs; .rt(-25, 420); .z(1); animation: dodongsilTft 2.5s 0.5s ease-in-out infinite;}
    .char2 img { .abs; .lt(-282, 913); .z(1); animation: dodongsilTft 2.5s 0.5s ease-in-out infinite;}
  }

  @media (@tl-up) {
    .info-section {
      .contents-container { .max-w(908); .h(728); .pt(54); .bg('@{tft-home}/sub-bg_pc.svg');
        .contents-holder { .max-w(808); .h(448); .pt(48); .mb(32); .mh-c; .bg('@{tft-home}/asset_2-2_pc.svg');
          .top {
            > p { .fs(24, 24); }
            > h4 { .fs(42, 42);}
          }
          .bottom {
            .img { .wh(660, 240);}
          }
        }
        .detail { .fs(24, 32); .mb(12);}
      }
      .divider { .w(472); .bg('@{tft-home}/div_pc.svg'); .mt(80); .mb(32);}
    }
    .chars { .block;
    }
  }

  @media (@ds-up) {
    .info-section {
      .contents-container { .max-w(1064); .bg('@{tft-home}/sub-bg_1920.svg');
        .contents-holder { .max-w(940); .bg('@{tft-home}/asset_2-2_1920.svg');

        }
      }
    }
    .chars {
      .char1 img { .r(-20);}
      .char2 img { .l(-184);}
    }
  }
}

</style>
