<template>
  <div tft-detail>
    <PSection class="contents-holder">
      <img class="logo" src="/img/pages/tftWeeklyCup/home/tft_logo.png">
      <div class="due-date-holder">
        <div class="apply-holder">
          <p class="title">접수기간</p>
          <div v-for="(item, idx) in applyDate" :key="`apply-${idx}`">
            <span class="apply-week">
              {{ item.week }}
            </span>
            <p class="apply-date">
              {{ item.date }}
            </p>
          </div>
        </div>
        <div class="play-holder">
          <p class="title">대회기간</p>
          <div v-for="(item, idx) in playDate" :key="`play-${idx}`">
            <span class="play-week">
              {{ item.week }}
            </span>
            <p class="play-date">
              {{ item.date }}
            </p>
          </div>
        </div>
      </div>
    </PSection>
  </div>
</template>

<script>
export default {
  name: 'TftDetail',
  data() {
    return {
      applyDate: [
        { week: '1주차', date: '1월 17일 (월) ~ 1월 27일 (목)' },
        { week: '2주차', date: '1월 17일 (월) ~ 2월 17일 (목)' },
        { week: '3주차', date: '1월 17일 (월) ~ 2월 24일 (목)' },
        { week: '4주차', date: '1월 17일 (월) ~ 3월 10일 (목)' },
        { week: '5주차', date: '1월 17일 (월) ~ 3월 17일 (목)' },
      ],
      playDate: [
        { week: '1주차', date: '1월 29일 (토)' },
        { week: '2주차', date: '2월 19일 (토)' },
        { week: '3주차', date: '2월 26일 (토)' },
        { week: '4주차', date: '3월 12일 (토)' },
        { week: '5주차', date: '3월 19일 (토)' },
      ],
    };
  },

};

</script>

<style lang="less">
@import '~@/less/proj.less';

[tft-detail] { .tc;
  .contents-holder { .mb(8); .mt(40); .bg('@{tft-home}/Frame_m.svg'); .bg-c; .no-repeat;.ani-base;
    .intro-up(1, { .ani-on});
    .logo { .contain; .wh(344, 264); .t-y(-10); }
    .due-date-holder {
      .apply-holder { .wh(284, 225); .bg('@{tft-home}/date_1_m.svg'); .bg-c; .no-repeat; .tc; .pt(40); .mh-c; .rel; .mb(24);
        > div { .mb(9); .ml(-5);
          &:nth-of-type(1) { .mt(10);}
          &:nth-of-type(4) { .ml(-6);}
        }
        .title { .c(white); .font-g; .bold !important; .fs(20, 20); .ls(-1); .medium; .abs; .block; .lt(49.5%, 9); transform: translateX(-50%); }
        .apply-week { .ib; .wh(46, 20); .br(20); .tc; .bgc(#01bdd6); .c(white); .fs(12, 20); .ls(-1); .bold; .mr(8);}
        .apply-date { .ib; .c(#0c444d); .fs(14, 21); .bold; .ls(-1);}
      }
      .play-holder { .wh(212, 225); .bg('@{tft-home}/date_2_m.svg'); .bg-c; .no-repeat; .tc; .pt(40); .mh-c; .rel;
        > div { .mb(9); .ml(-5);
          &:nth-of-type(1) { .mt(10);}
          &:nth-of-type(4) { .ml(-5);}
        }
        .title { .c(white); .font-g; .bold !important; .fs(20, 20); .ls(-1); .medium; .abs; .block; .lt(49.5%, 9); transform: translateX(-50%); }
        .play-week { .ib; .wh(46, 20); .br(20); .tc; .bgc(#ce00d2); .c(white); .fs(12, 20); .ls(-1); .bold; .mr(8);}
        .play-date { .ib; .c(#0c444d); .fs(14, 21); .bold; .ls(-1); }
      }
    }
  }

  @media (@tl-up) {
    .contents-holder { .mt(45); .bg('@{tft-home}/Frame_pc.svg'); .w(110%); .ml(-5%); .bg-c;
      .logo { .ib; .vam; .wh(470, 357); .t-y(0);}
      .due-date-holder { .ib; .vam; .t-y(10);
        .apply-holder { .ib; .wh(316, 241); .bg('@{tft-home}/date_1_pc.svg'); .mr(24);
          .title { .t(9);}
          .apply-week { .wh(58, 24); .fs(14, 24);}
          .apply-date { .fs(15, 22.5);}
        }
        .play-holder { .ib; .wh(212, 241); .bg('@{tft-home}/date_2_pc.svg');
          .title { .t(9);}
          .play-week { .wh(58, 24); .fs(14, 24);}
          .play-date { .fs(15, 22.5);}
        }
      }
    }
  }

  @media (@ds-up) {
    .contents-holder {
      .logo { .wh(520, 400);}
    }
  }
}

</style>
